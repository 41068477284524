import axios from 'axios'

function postLogin(params) {
    return axios.post('/a/login', params)
}
function GetchannelData (params) {
    return axios.post('/a/u/channelData', params )
}

export { postLogin, GetchannelData }
