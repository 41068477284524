/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

import Datepicker from 'components/Datepicker.jsx'
import { GetchannelData } from 'http/http.js'

const dateNow = new Date()

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pvAndUv: {
                new: { pv: 0, uv: 0 },
                old: {pv: 0, uv: 0},
            },
            aid: 0, 
        }
    }

    channels = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')).channels : []

    handleChange = (event) => {
        this.getData(event.target.value)
        this.props.toggleAid(event.target.value)
    }

    componentWillMount () {
        this.getData()
    }
    getData = (aid) => {
        const params = {
            uid: +(JSON.parse(localStorage.getItem('user')).user.id),
            page: 1,
            size: 99999,
            startAt: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate()).getTime() - 3600000*48,
            endAt: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate()).getTime(),
        }
        if (+aid !== 0) {
            params.aid = +aid
        }
        GetchannelData(params).then(res => {
            if (res.data.code === 200) {                
                const newData = res.data.results
                const filterData = []
                newData.forEach(item => {
                    let isEq = false
                    if (filterData.length > 0) {
                        filterData.forEach(v => {
                            if (v.date === item.date) {
                                v.pv += item.pv
                                v.uv += item.uv
                                isEq = true
                            }
                        })
                        if (isEq === false) {
                            filterData.push(item)
                        }
                    } else {
                        filterData.push(item)
                    }
                })
    
                const pvAndUv = {
                    new: filterData[0] || { pv: 0, uv: 0 },
                    old: filterData[1] || { pv: 0, uv: 0 },
                }
    
                this.setState({pvAndUv})
            }
        })
    }

    render () {
      const { pvAndUv } = this.state
    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                        {/* Card stats */}
                        <Row>
                            <Col lg="6" xl="3">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    新增\pv
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {pvAndUv.new.pv}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-bar" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-nowrap mr-2">
                                                昨日
                                            </span>
                                            <span className={`text-${
                                                pvAndUv.new.uv /
                                                    pvAndUv.old.uv -
                                                    1 <=
                                                    0
                                                    ? 'danger'
                                                    : 'success'
                                                } mr-2`}>
                                                <i
                                                    className={`fas fa-arrow-${
                                                        pvAndUv.new.pv /
                                                            pvAndUv.old.pv -
                                                            1 <=
                                                        0
                                                            ? 'down'
                                                            : 'up'
                                                    }`}
                                                />{' '}
                                                {(pvAndUv.old.pv) ? (Math.floor(
                                                    Math.abs(
                                                        pvAndUv.new.pv /
                                                            pvAndUv.old.pv -
                                                            1
                                                    ) * 1000
                                                ) / 100) : pvAndUv.new.pv}
                                                %
                                            </span>{' '}
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    日活\uv
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {pvAndUv.new.uv}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-pie" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-nowrap mr-2">
                                                昨日
                                            </span>
                                            <span className={`text-${
                                                pvAndUv.new.uv /
                                                    pvAndUv.old.uv -
                                                    1 <=
                                                    0
                                                    ? 'danger'
                                                    : 'success'
                                                } mr-2`}>
                                                <i
                                                    className={`fas fa-arrow-${
                                                        pvAndUv.new.uv /
                                                            pvAndUv.old.uv -
                                                            1 <=
                                                        0
                                                            ? 'down'
                                                            : 'up'
                                                    }`}
                                                />{' '}
                                                {(pvAndUv.old.uv) ? (Math.floor(
                                                    Math.abs(
                                                        pvAndUv.new.uv /
                                                            pvAndUv.old.uv -
                                                            1
                                                    ) * 1000
                                                ) / 100) : pvAndUv.new.uv}
                                                %
                                            </span>{' '}
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="2">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <form style={{paddingBottom: 10}}>
                                        <label htmlFor="sel1">渠道:</label>
                                        <select className="form-control" id="sel1" name="channel" value={this.props.aid} onChange={this.handleChange}>
                                            <option value={0} >全部</option>
                                            {this.channels.map(item => (
                                                <option value={item.id}  key={item.id}>{item.name}</option>
                                            ))}
                                            </select>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Datepicker
                                            {...this.props}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Performance
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            49,65%
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col> */}
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
  }
}

export default Header;
