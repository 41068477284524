/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert,
} from 'reactstrap'
import { postLogin } from 'http/http.js'

class Login extends React.Component {
    state = {
        user: {},
        message: '',
        loading: false,
    }
    componentDidMount() {
        this.btnRef.current.addEventListener('click', () => {
            this.onLogin(this.userRef.current.value, this.pwdRef.current.value)
        })
    }

    btnRef = React.createRef()
    userRef = React.createRef()
    pwdRef = React.createRef()

    onLogin = (name, pwd) => {
        if (name.length >= 5 && pwd.length >= 5) {
            postLogin({ name, pwd }).then(({data}) => {
                if (data.code === 200) {
                    data.data.user.name = name
                    localStorage.setItem('user', JSON.stringify(data.data));
                    this.props.history.push({pathname: '/home/admin/index'})
                } else {
                    setTimeout(() => this.setState({ message: '' }), 2000);
                    if (data.message === 'not user') {
                        this.setState({ message: '用户不存在' });
                    }
                } 
            })
        } else {
            setTimeout(() => this.setState({ message: '' }), 2000);
            if (name.length === 0) {
                this.setState({ message: '请输入用户名' });
                return;
            }
            if (name.length < 5) {
                this.setState({ message: '请输入6位及以上长度用户名' });
                return;
            }
            if (pwd.length === 0) {
                this.setState({ message: '请输入密码' });
                return;
            }
            if (pwd.length < 5) {
                this.setState({ message: '请输入6位及以上长度密码' });
                return;
            }
        }
    }
    render() {
        return (
            <>
                <Col lg="5" md="7">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-muted text-center mt-2 mb-3">
                                <small>登 录</small>
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-single-02" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="用户名"
                                            type="text"
                                            innerRef={this.userRef}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="密码"
                                            type="password"
                                            innerRef={this.pwdRef}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-center">
                                    <Alert color="secondary" isOpen={this.state.message ? true : false} >
                                        {this.state.message}
                                        <span
                                            class={
                                                this.state.loading
                                                    ? 'dotting'
                                                    : 'dotting d-none'
                                            }
                                        ></span>
                                    </Alert>
                                    <Button
                                        className="my-4"
                                        color="primary"
                                        type="button"
                                        innerRef={this.btnRef}
                                    >
                                        登 录
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </>
        )
    }
}

export default Login
