/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// node.js library that concatenates classes (strings)
import classnames from 'classnames'
// javascipt plugin for creating charts
import Chart from 'chart.js'
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
} from 'reactstrap'

// core components
import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
} from 'variables/charts.jsx'

import Header from 'components/Headers/Header.jsx'

class Index extends React.Component {
    state = {
        activeNav: 1,
        chartExample1Data: 'data2',
        data: {},
        aid: 0,
    }
    toggleAid = aid => this.setState({aid})
    toggleNavs = (e, index) => {
        e.preventDefault()
        this.setState({
            activeNav: index,
            chartExample1Data:
                this.state.chartExample1Data === 'data1' ? 'data2' : 'data1',
        })
        let wow = () => {
        }
        wow.bind(this)
        setTimeout(() => wow(), 1000)
        // this.chartReference.update();
    }
    componentWillMount() {
        if (window.Chart) {
            parseOptions(Chart, chartOptions())
        }
    }
    getDate = (newData) => {
        let filterData = []
        newData.forEach(item => {
           let isEq = false
            if (filterData.length > 0) {
                filterData.forEach(v => {
                    if (v.date === item.date) {
                        v.pv = +v.pv + +item.pv
                        v.uv = +v.uv + +item.uv
                        isEq = true
                    }
                })
                if (isEq === false) {
                    filterData.push(item)
                }
            } else {
                filterData.push(item)
            }
        })
        let pvs = [], uvs = [], labels = []
        for (let i = filterData.length - 1; i >= 0; i--) {
            labels.push(filterData[i].date.replace(/\d{4}-/, ''))
            pvs.push(filterData[i].pv)
            uvs.push(filterData[i].uv)
        }

        const pvAndUv = {
            new: filterData[filterData.length-1],
            old: filterData[filterData.length-2],
        }

        const data = {
            labels: labels,
            datasets: [
                {
                    label: '日活\\uv',
                    borderWidth: 1.5,
                    pointRadius: 3,
                    borderColor: '#ff6384',
                    pointBorderColor: '#fff',
                    pointBackgroundColor: '#ff6384',
                    data: uvs,
                },
                {
                    label: '新增\\pv',
                    borderWidth: 1.5,
                    pointRadius: 3,
                    borderColor: '#82c4f3',
                    pointBorderColor: '#fff',
                    pointBackgroundColor: '#82c4f3',
                    data: pvs,
                }
            ],
        }
        this.setState({ data, pvAndUv })
    }
    render () {
        const quadrate = {
            width: 10,
            height: 10,
        }
        return (
            <>
                <Header getDate={this.getDate} toggleAid={this.toggleAid} aid={this.state.aid} />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="bg-gradient-default shadow">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                概览
                                            </h6>
                                        </div>
                                        <div className="col text-center">
                                            <span className="mr-3 text-light">
                                            <i className="ni mr-1" style={{ ...quadrate, background: '#ff6384'}}></i>
                                            日活\uv
                                            </span>
                                            <span className="text-light">
                                            <i className="ni mr-1" style={{ ...quadrate, background: '#82c4f3'}}></i>
                                            新增\pv
                                            </span>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {/* Chart */}
                                    <div className="chart">
                                        <Line
                                            data={this.state.data}
                                            options={chartExample1.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Total orders</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody> */}
                        {/* Chart */}
                        {/* <div className="chart">
                    <Bar
                      data={chartExample2.data}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
                    </Row>
                </Container>
            </>
        )
    }
}

export default Index
