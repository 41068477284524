/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import 'assets/vendor/nucleo/css/nucleo.css'
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import "assets/scss/argon-dashboard-react.scss"

import AdminAdmin from 'layouts/Admin.jsx'
import AuthLayout from 'layouts/Auth.jsx'

require('moment/locale/zh-cn')

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            JSON.parse(localStorage.getItem('user')) &&
            JSON.parse(localStorage.getItem('user')).user.channelname ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/home/auth/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
)

const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            JSON.parse(localStorage.getItem('user')) &&
            JSON.parse(localStorage.getItem('user')).user.channelname ? (
                <Redirect
                    to={{
                        pathname: '/home/admin/index',
                        state: { from: props.location },
                    }}
                />
            ) : (
                <Component {...props} />
            )
        }
    />
)

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <PrivateRoute path="/home/admin" component={AdminAdmin} />
            <LoginRoute path="/home/auth" component={AuthLayout} />
            <Redirect from="/home" to="/home/admin/index" />
            <Redirect from="/" to="/home/admin/index" />
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
)
