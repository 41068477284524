/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'

// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
} from 'reactstrap'
// core components
import Header from 'components/Headers/Header.jsx'
import Index from '../Index'

const pagingData = [10, 25, 50, 100, 150, 200]
const PageSize = ({ dropdownOpen, toggle, data, size }) => (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="">
        <DropdownToggle
            caret
            style={{
                border: '#5e72e4 solid 1px',
                padding: 7,
                fontSize: 12,
            }}
        >
            {size}条/页
        </DropdownToggle>
        <DropdownMenu>
            {data.map((val, key) => (
                <DropdownItem
                    key={key}
                    onClick={() => toggle(null, val)}
                    active={size === val}
                >
                    {val}条/页
                </DropdownItem>
            ))}
        </DropdownMenu>
    </Dropdown>
)

const Paging = ({ total, page, togglePage }) => {
    let result = []
    let baseCount = 2 * 2 + 1 + 2 + 2 + 2 //总共元素个数
    let surplus = baseCount - 4 //只出现一个省略号 剩余元素个数
    let startPosition = 1 + 2 + 2 + 1 //前面出现省略号的临界点
    let endPosition = total - 2 - 2 - 1 //后面出现省略号的临界点

    if (total <= baseCount - 2) {
        //全部显示 不出现省略号
        result = Array.from({ length: total }, (v, i) => i + 1)
    } else {
        //需要出现省略号
        if (page < startPosition) {
            //1.只有后面出现省略号
            result = [
                ...Array.from({ length: surplus }, (v, i) => i + 1),
                '...',
                total,
            ]
        } else if (page > endPosition) {
            //2.只有前边出现省略号
            result = [
                1,
                '...',
                ...Array.from(
                    { length: surplus },
                    (v, i) => total - surplus + i + 1
                ),
            ]
        } else {
            //3.两边都有省略号
            result = [
                1,
                '...',
                ...Array.from({ length: 2 * 2 + 1 }, (v, i) => page - 2 + i),
                '...',
                total,
            ]
        }
    }

    return (
        <>
            <PaginationItem className="disabled">
                <PaginationLink
                    href="#pablo"
                    onClick={e => {
                        togglePage(page - 1)
                        e.preventDefault()
                    }}
                    tabIndex="-1"
                >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                </PaginationLink>
            </PaginationItem>
            {result.map((val, i) => {
                if (val !== '...') {}
                return (
                    <PaginationItem
                        className={page === val ? 'active' : ''}
                        key={i}
                    >
                        {val !== '...' ? (
                            <PaginationLink
                                href="#pablo"
                                onClick={e => {
                                    togglePage(val)
                                    e.preventDefault()
                                }}
                            >
                                {' '}
                                {val}
                            </PaginationLink>
                        ) : (
                            <PaginationLink
                                href="#pablo"
                                onClick={e => {
                                    e.preventDefault()
                                }}
                            >
                                {' '}
                                {val}
                            </PaginationLink>
                        )}
                    </PaginationItem>
                )
            })}

            <PaginationItem>
                <PaginationLink href="#pablo" onClick={e => {
                    togglePage(page + 1)
                    e.preventDefault()
                }}>
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                </PaginationLink>
            </PaginationItem>
        </>
    )
}

class Tables extends React.Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = {
            dropdownOpen: false,
            page: {
                size: 10,
                page: 1,
            },
            data: [],
            total: 0,
            adi: 0,
        }
    }

    toggleAid = aid => this.setState({ aid })
    togglePage = page => this.setState({page: { ...this.state.page, page: page }})
    
    toggle (isNull, val) {
        if (!isNull) {
            this.setState(prevState => {
                return {
                    page: { ...prevState.page, size: val },
                }
            })
            return
        }
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }))
    }

    getDate = (newData, total = 15) => {
        this.setState({ data: newData, total })
    }

    render () {
        const { data } = this.state
        return (
            <>
                <Header {...this.state.page} aid={this.state.aid} toggleAid={this.toggleAid} getDate={this.getDate} />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                {/* <CardHeader className="border-0">
                                    <h3 className="mb-0"></h3>
                                </CardHeader> */}
                                <Table
                                    className="align-items-center table-flush"
                                    responsive
                                >
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">类型</th>
                                            <th scope="col">渠道</th>
                                            <th scope="col">日期</th>
                                            <th scope="col">pv/新增</th>
                                            <th scope="col">uv/日活</th>
                                            <th scope="col">收益</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            {data.map(
                                                (obj, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{index+1}</th>
                                                        <td>{obj.ps}</td>
                                                        <td>{obj.channel}</td>
                                                        <td>{obj.date}</td>
                                                        <td>{obj.pv}</td>
                                                        <td>{obj.uv}</td>
                                                        <td>{obj.income}</td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </Table>
                                <CardFooter className="py-4">
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0 "
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PageSize
                                                dropdownOpen={this.state.dropdownOpen}
                                                toggle={this.toggle}
                                                data={pagingData}
                                                size={this.state.page.size}
                                            />
                                            <Paging total={Math.ceil(this.state.total/this.state.page.size)} togglePage={this.togglePage} page={this.state.page.page} />
                                        </Pagination>
                                    </nav>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Tables
