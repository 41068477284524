import React from 'react'
// react plugin used to create datetimepicker
import ReactDatetime from 'react-datetime'

// reactstrap components
import {
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    Row,
    Button,
} from 'reactstrap'
import { GetchannelData } from 'http/http.js'

const dateNow = new Date()
const date = {
    startAt: new Date(new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate()).getTime() - (1000 * 60 * 60 * 24 * 30)),
    endAt: new Date(new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate()).getTime() - 1),
}
export default class Datepicker extends React.Component {
    state = {
        startDate: {
            _d: date.startAt,
        },
        endDate: {
            _d: date.endAt,
        },
        startAt: date.startAt.getTime(),
        endAt: date.endAt.getTime(),
    }
    componentWillMount () {
        this.getData()
    }
    componentDidUpdate(prevProps) {
        // 典型用法（不要忘记比较 props）：
        if (this.props.aid !== prevProps.aid || this.props.size !== prevProps.size || this.props.page !== prevProps.page) {
            this.getData()
        }
    }

    clickGetData = () => {
        const { startDate, endDate } = this.state
        const date = { startAt: startDate._d.getTime(), endAt: endDate._d.getTime() }
        this.setState({ ...date})
        this.getData(date)
    }
    
    getData = (time = {}) => {
        const { page, size, aid } = this.props
        const params = {
            uid: +(JSON.parse(localStorage.getItem('user')).user.id),
            page: page || 1,
            size: size || 99999,
            startAt: this.state.startAt,
            endAt: this.state.endAt,
            ...time,
        }
        if (+aid !== 0) {
            params.aid = +aid
        }
        GetchannelData(params).then(res => {
            if (res.data.total) {
                this.props.getDate(res.data.results, res.data.total)
            } else {
                this.props.getDate(res.data.results)
            }
        })
    }
    render() {
        return (
            <>
                <Row>
                    <Col sm="6" md="5" xs="12">
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <ReactDatetime
                                    defaultValue={this.state.startDate._d}
                                    inputProps={{
                                        placeholder: '开始日期',
                                    }}
                                    timeFormat={false}
                                    renderDay={(
                                        props,
                                        currentDate,
                                        selectedDate
                                    ) => {
                                        let classes = props.className
                                        if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            this.state.startDate._d + '' ===
                                                currentDate._d + ''
                                        ) {
                                            classes += ' start-date'
                                        } else if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            new Date(
                                                this.state.startDate._d + ''
                                            ) < new Date(currentDate._d + '') &&
                                            new Date(
                                                this.state.endDate._d + ''
                                            ) > new Date(currentDate._d + '')
                                        ) {
                                            classes += ' middle-date'
                                        } else if (
                                            this.state.endDate &&
                                            this.state.endDate._d + '' ===
                                                currentDate._d + ''
                                        ) {
                                            classes += ' end-date'
                                        }
                                        return (
                                            <td {...props} className={classes}>
                                                {currentDate.date()}
                                            </td>
                                        )
                                    }}
                                    onChange={e => {
                                        this.setState({ startDate: e })
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col sm="6" md="5" xs="12">
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <ReactDatetime
                                    defaultValue={this.state.endDate._d}
                                    inputProps={{
                                        placeholder: '结束日期',
                                    }}
                                    timeFormat={false}
                                    renderDay={(
                                        props,
                                        currentDate,
                                        selectedDate
                                    ) => {
                                        let classes = props.className
                                        if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            this.state.startDate._d + '' ===
                                                currentDate._d + ''
                                        ) {
                                            classes += ' start-date'
                                        } else if (
                                            this.state.startDate &&
                                            this.state.endDate &&
                                            new Date(
                                                this.state.startDate._d + ''
                                            ) < new Date(currentDate._d + '') &&
                                            new Date(
                                                this.state.endDate._d + ''
                                            ) > new Date(currentDate._d + '')
                                        ) {
                                            classes += ' middle-date'
                                        } else if (
                                            this.state.endDate &&
                                            this.state.endDate._d + '' ===
                                                currentDate._d + ''
                                        ) {
                                            classes += ' end-date'
                                        }
                                        return (
                                            <td {...props} className={classes}>
                                                {currentDate.date()}
                                            </td>
                                        )
                                    }}
                                    onChange={e =>
                                        this.setState({ endDate: e })
                                    }
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={2}>
                        <Button color="info" onClick={this.clickGetData}>应用</Button>{' '}
                    </Col>
                </Row>
            </>
        )
    }
}
