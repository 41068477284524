/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.jsx";
import Login from "views/examples/Login.jsx";
import Tables from "views/examples/Tables.jsx";

var routes = [
  {
    path: "/index",
    name: "首页",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/home/admin"
  },
//   {
//     path: "/icons",
//     name: "Icons",
//     icon: "ni ni-planet text-blue",
//     component: Icons,
//     layout: "/admin"
//   },
//   {
//     path: "/maps",
//     name: "Maps",
//     icon: "ni ni-pin-3 text-orange",
//     component: Maps,
//     layout: "/admin"
//   },
//   {
//     path: "/user-profile",
//     name: "User Profile",
//     icon: "ni ni-single-02 text-yellow",
//     component: Profile,
//     layout: "/admin"
//   },
  {
    path: "/tables",
    name: "详细数据",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/home/admin"
  },
  {
    path: "/login",
    name: "退出",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/home/auth"
  },
//   {
//     path: "/register",
//     name: "Register",
//     icon: "ni ni-circle-08 text-pink",
//     component: Register,
//     layout: "/auth"
//   }
];
export default routes;
